
export default {
    name: 'ProductResourceThree',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            list: [],
            visible: false,
            swiperCompo: {
                watchOverflow: true
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                if(n.productCode) {
                    this.getResourceAjax()
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        getResourceAjax() {
            this.$api.common.getAd({
                places: 16,
            }).then(response => {
                const res = response.result || [];
                this.list = res
                this.visible = !!this.list.length
            })
        },
        handleAd(obj) {
            obj.jumpUrl && (window.location.href = obj.jumpUrl)
        }
    },
}
