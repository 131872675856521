
export default {
    name: 'ProductEmpty',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        jumpBack() {
            this.$router.back()
        }
    },
}
