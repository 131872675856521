
export default {
    name: 'ProductSeo',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        rec: Object
    },
    data() {
        return {
            currency: {}
        }
    },
    computed: {},
    watch: {},
    created() {
        this.currency = this.$storage.get('currency') || {}
    },
    mounted() {},
    methods: {},
}
