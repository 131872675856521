import { render, staticRenderFns } from "./ProductPolicyPopup.vue?vue&type=template&id=054f3342&scoped=true&"
import script from "./ProductPolicyPopup.vue?vue&type=script&lang=js&"
export * from "./ProductPolicyPopup.vue?vue&type=script&lang=js&"
import style0 from "./ProductPolicyPopup.vue?vue&type=style&index=0&id=054f3342&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "054f3342",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
