
export default {
    name: 'ProductReviewContent',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmitChild: Object,
        info: Object
    },
    data() {
        return {
            content: {
                hasMore: false,
                moreStatus: true,
                moreText: '',
                text: '',
                translateText: '',
                hasTranslate: false,
                btnText: '',
                status: 1, // 1 未翻译 2 翻译中 3 翻译完成
                sourceLang: '',
                targetLang: '',
                hasInitial: -1,
            },
            reply: {
                hasMore: false,
                moreStatus: false,
                moreText: '',
                text: '',
            },
            media: {
                videoTarget: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.setContent()
        this.setReply()
    },
    methods: {
        setContent() {
            const lang = this.$storage.get('language');
            const originText = this.content.status == 3 && this.content.translateText ? this.content.translateText : this.info.commentContent;
            if(originText) {
                this.content.hasMore = originText.length > 400
                this.setMore()
            }
            this.content.sourceLang = this.info.language || ''
            this.content.targetLang = lang.identify
            this.content.hasInitial += 1
            this.setBtnStatus()
            this.emitSetContent()
        },
        handleMore() {
            this.content.moreStatus = !this.content.moreStatus
            this.setMore()
        },
        setMore() {
            const originText = this.content.status == 3 && this.content.translateText ? this.content.translateText : this.info.commentContent;
            this.content.moreText = this.$translate(this.content.moreStatus ? 'See More' : 'See less')
            this.content.text = this.content.moreStatus ? originText.substr(0, 400) : originText
        },
        setReply() {
            if(this.info.replyContent) {
                this.reply.text = `${this.info.replyContent.substr(0, 80)}`
                this.reply.hasMore = this.info.replyContent.length > 80
                this.reply.moreStatus = true
                this.reply.moreText = this.$translate('See More')
            }
        },
        handleReplyMore() {
            this.reply.moreStatus = !this.reply.moreStatus
            this.reply.moreText = this.$translate(this.reply.moreStatus ? 'See More' : 'See less')
            this.reply.text = this.reply.moreStatus ? this.info.replyContent.substr(0, 80) : this.info.replyContent
        },
        // 翻译
        getTranslateTextAjax() {
            this.$api.common.setTranslateText({
                sourceLang: this.content.sourceLang,
                targetLang: this.content.targetLang,
                content: [this.info.commentContent]
            }).then(response => {
                const res = response?.result || [];
                if(response.code == 'success') {
                    this.content.translateText = res[0] || ''
                    this.content.status = 3
                } else {
                    this.content.status = 1
                }
                this.setContent()
            })
        },
        setBtnStatus() {
            const btnText = ['Translate', 'Translating', 'See Original'][this.content.status - 1];
            this.content.btnText = `${this.$translate(btnText)}${this.content.status == 2 ? '...' : ''}`
        },
        handleTranslate(obj) {
            if(this.content.status == 2) return
            if(this.content.status == 1) {
                this.content.status = 2
                if(this.content.translateText) {
                    this.content.status = 3
                    this.setContent()
                } else {
                    this.getTranslateTextAjax()
                }
                this.setBtnStatus()
                this.emitSetContent()
            } else {
                this.content.status = 1
                this.setContent()
            }
        },
        // 媒体预览
        handleMedia(obj) {
            this.middleEmitChild.fn('handleMedia', {...this.info, ...obj})
        },
        emitSetContent() {
            this.middleEmitChild.fn('emitSetContent', {info: this.info, ...this.content})
        },
    },
}
