
export default {
    name: 'ProductKeyword',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            keyword: {
                visible: false,
                list: [],
                isMore:false
            },
            keywordList:(e)=>{
                return this.keyword.isMore ? e : e.slice(0,8)
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.keyword.list = this.info.keyword || []
            this.keyword.list.forEach(p => {
                p.length > 8 && (p = p.slice(0, 8))
            })
            this.keyword.visible = !!this.keyword.list.length
        },
        handleKeyword(val) {
            this.$router.push({
                name: 'Search',
                query: {
                    keyword: val
                }
            })
        }
    },
}
