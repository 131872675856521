
import ProductReviewContent from './ProductReviewContent'
import ProductReviewPreview from './ProductReviewPreview'
import ProductReviewTranslate from './ProductReviewTranslate'

export default {
    name: 'ProductReview',
    components: {
        ProductReviewContent,
        ProductReviewPreview,
        ProductReviewTranslate
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object,
    },
    data() {
        return {
            clear: '',          // 节流
            hasLogin: false,    // 是否登录
            hasLoading: false,  // loading加载
            lang: {},
            middleEmitChild: {},
            score: {            // 总评分
                rate: 0,        // 好评率
                list: [],       // 好评列
            },
            sort: {             // 排序
                list: []
            },
            comment:{           // 评论
                list: [],
            },
            commentAll: {       // 总评论
                list: [],
                media: [],
                mediaLength: 0
            },
            page: {             // 分页
                currentPage: 1, // 当前页
                total: 0,       // 总数量
                totalPage: 1,   // 总页数
                pageSize: 5,
            },
            params: {           // 评论接口参数
                detail: false,
                sortColumn: '',
                queryType: '',
                sortId: ''
            }
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.getCommentsAjax()
                this.getAllCommentAjax()
            },
            deep: true,
            immediate: true,
        }
    },
    created() {
        this.initialSet()
    },
    mounted() {
        this.$nextTick(() => {
            this.initialSetMounted()
        })
    },
    methods: {
        // 获取评论
        getCommentsAjax() {
            this.$api.review.getReview({
                id: this.info.id,
                spuId: this.info.spuId,
                pageNum: this.page.currentPage,
                pageSize: this.page.pageSize,
                ...this.params
            }, {
                hasLoading: this.hasLoading,
            }).then(response => {
                const res = response?.result || {}
                // 评分
                this.score.rate = +(res.scoreAverage / 10000).toFixed(1)
                this.score.list = res?.starList?.reverse() || []
                this.score.list.forEach(item => {
                    item.percent = `${(item.commentStar / res.allNum * 100).toFixed(0)}%`
                })
                // 评论
                this.comment.list = res.list || []
                this.comment.list.forEach(item => {
                    // 评论-头部时间处理
                    item.gmtCreate = this.$dayjs(item.gmtCreate).format('YYYY/MM/DD')
                    // 评论-评论图片处理
                    let commentPics = [];
                    let filterVideo = []
                    if(item.commentPics) {
                        item.commentPics.forEach(p => {
                            if(!p.includes('mp4')) {
                                commentPics.push({
                                    url: p,
                                    hasPic: true
                                })
                            } else {
                                filterVideo.push({
                                    url: p
                                })
                            }
                        })
                    }
                    let commentVideo = item.commentVideo || [];
                    item.rsCommentMedia = [...commentVideo, ...filterVideo, ...commentPics]
                    item.hasCommentMedia = !!item.rsCommentMedia.length
                    // 评分
                    item.commentStar = +item.commentStar
                    // 属性
                    let propArr = [];
                    item.specList && item.specList.forEach(c => {
                        propArr.push(`${c.specName}:${c.specVal}`)
                    })
                    item.propStr = propArr.join('/')
                })
                // 分页
                this.page.total = res.total
                this.page.totalPage = res.totalPage
                this.middleEmit.fn('setReviewData', {
                    ...this.page,
                    rate: this.score.rate
                })
            })
        },
        // 获取所有评论
        getAllCommentAjax() {
            this.$api.review.getReview({
                id: this.info.id,
                spuId: this.info.spuId,
                detail: false,
                sortColumn: '',
                sortId: '',
                pageNum: 1,
                pageSize: 9999,
                queryType: 1
            }, {
                hasLoading: false,
            }).then(response => {
                const res = response?.result || {}
                // 评论
                this.commentAll.list = res.list || []
                this.commentAll.media = []
                this.commentAll.mediaLength = 0
                this.commentAll.list.forEach((item, pI) => {
                    // 评论-头部时间处理
                    item.gmtCreate = this.$dayjs(item.gmtCreate).format('YYYY/MM/DD')
                    // 评论-评论图片处理
                    let commentPics = [];
                    let filterVideo = []
                    if(item.commentPics) {
                        item.commentPics.forEach(p => {
                            if(!p.includes('mp4')) {
                                commentPics.push({
                                    url: p,
                                    hasPic: true
                                })
                            } else {
                                filterVideo.push({
                                    url: p
                                })
                            }
                        })
                    }
                    let commentVideo = item.commentVideo || [];
                    item.rsCommentMedia = [...commentVideo, ...filterVideo, ...commentPics]
                    item.hasCommentMedia = !!item.rsCommentMedia.length
                    item.rsCommentMedia.forEach((p, cI) => {
                        p.videoRef = `video${pI}${cI}`
                    })
                    if(item.hasCommentMedia) {
                        this.commentAll.media.push(item)
                    }
                    this.commentAll.mediaLength += (commentVideo.length + commentPics.length)
                    // 评分
                    item.commentStar = +item.commentStar
                    // 属性
                    let propArr = [];
                    item.specList && item.specList.forEach(c => {
                        propArr.push(`${c.specName}:${c.specVal}`)
                    })
                    item.propStr = propArr.join('/')
                })
            })
        },
        // 评论排序
        handleSort(obj) {
            if(this.info.id == obj.id) return
            if(obj.id == 1) {
                this.params.sortColumn = ''
                this.params.queryType = obj.id
            } else {
                this.params.sortColumn = obj.id
                this.params.queryType = ''
            }
            this.params.sortId = obj.id
            this.page.currentPage = 1
            this.hasLoading = true
            this.getCommentsAjax()
        },
        changePage(e) { // 分页
            this.$fnApi.debounce(() => {
                this.page.currentPage = e
                this.hasLoading = true
                this.getCommentsAjax()
                this.setPoint()
            }, 200)
        },
        setPoint() { // 锚点定位
            this.$VueScrollTo.scrollTo('.ProductReview', '', {
                offset: -60
            })
        },
        setAgree(obj) { // 点赞
            // 未登录跳转
            if(!this.hasLogin) {
                this.$router.push('/login')
                return
            }
            let agree = this.comment.list[obj.index]
            this.$api.review.setAgree({
                spuCommentId: obj.id,
                status: !agree.likeStatus
            }).then(response => {
                const res = response?.result
                if(res) {
                    agree.likeStatus = !agree.likeStatus
                    if(agree.likeStatus) {
                        agree.helpFul += 1
                    } else {
                        agree.helpFul -= 1
                    }
                }
            })
        },
        goComment() { // 更多评论展示
            this.$router.push({
                name: 'review',
                params: {
                    spuId: this.info.spuId
                }
            })
        },
        // emit
        handleMedia(obj) { // 媒体预览
            const ref = this.$refs.ProductReviewPreview;
            ref && ref.handleMedia({
                preview: obj,
                comment: this.commentAll
            }, true)
        },
        emitSetContent(obj) { // 评论内容
            if(!obj.hasInitial) return
            const i = obj.info?.$index;
            const ref = this.$refs[`ProductReviewTranslate${i}`];
            ref[0] && ref[0].setContentData(obj)
        },
        handleTranslate(obj) { // 评论翻译
            const i = obj.info?.$index;
            const ref = this.$refs[`ProductReviewContent${i}`];
            ref[0] && ref[0].handleTranslate(obj)
        },
        // 初始化
        initialSet() {
            this.middleEmitChild.fn = (fn, options, ...arg) => {
                return this[fn] && this[fn](options, ...arg)
            }
        },
        initialSetMounted() {
            this.hasLogin = this.$login()
            this.lang = this.$storage.get('language')
            this.sort.list = [{
                id: '',
                name: this.$translate('All')
            }, {
                id: 1,
                name: this.$translate('With Photo')
            }, {
                id: 'helpFul:DESC',
                name: this.$translate('Most Helpful')
            }, {
                id: 'gmtCreate:DESC',
                name: this.$translate('Most Recent')
            }, {
                id: 'commentStar:ASC',
                name: this.$translate('Most Critical')
            }]
        }
    },
}
