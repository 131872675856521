
import ProductReviewHelpful from './ProductReviewHelpful'

export default {
    name: 'ProductReviewPreview',
    components: {
        ProductReviewHelpful
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmitChild: Object
    },
    data() {
        return {
            preview: {},
            comment: {},
            media: {
                targetIndex: 1,
                pI: 0,
                cI: 0,
                length: 0
            },
            video: {
                target: '',
                hasPause: true
            },
            popupCompo: {
                visible: false
            },
            swiperCompo: {
                initialSlide: 0,
                on: {
                    slideChange: e => this.slideChange(),
                }
            },
            swiperCompoChild: {
                initialSlide: 0,
                on: {
                    slideChange: e => this.slideChange(),
                }
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 设置视频/图片数量
        setMediaIndex() {
            let pIndex = 0;
            let cIndex = 0;
            let targetIndex = 0;
            let hasSearched = false
            this.comment.media.some((p, pI) => {
                p.rsCommentMedia.some((c, cI) => {
                    targetIndex += 1
                    if(c.url == this.preview.url) {
                        pIndex = pI
                        cIndex = cI
                        hasSearched = true
                        return true
                    }
                })
                if(hasSearched) return true
            })
            return {
                pIndex,
                cIndex,
                targetIndex
            }
        },
        // 初始化media
        handleMedia(obj, bool) {
            this.preview = obj.preview || {}
            this.comment = obj.comment || {}
            const {pIndex, cIndex, targetIndex} = this.setMediaIndex();
            this.$fnApi.debounce(() => {
                this.swiperCompo.initialSlide = pIndex
                this.swiperCompoChild.initialSlide = cIndex
                this.media.targetIndex = targetIndex
                this.popupCompo.visible = bool
            }, 10)
        },
        // 切换
        slideChange() {
            this.$fnApi.debounce(() => {
                const parent = this.$refs.swiperParent;
                const parentSwiper = parent.$refs['ProductReviewPreview-swiper'].swiper;
                this.media.pI = parentSwiper.activeIndex || 0
                const child = this.$refs[`swiperChild${this.media.pI}`]
                const childSwiper = child.$refs['ProductReviewPreview-swiper-child'].swiper;
                // 获取当前信息
                this.media.cI = childSwiper.activeIndex || 0
                const pInfo = this.comment.media[this.media.pI]
                const cInfo = pInfo.rsCommentMedia[this.media.cI];
                this.preview = {...pInfo, ...cInfo}
                const {targetIndex} = this.setMediaIndex();
                this.media.targetIndex = targetIndex
            }, 100)
            this.setVideoPause()
        },
        // 视频图片播放
        setVideoPause() { // 视频暂停
            if(this.video.target && !this.video.hasPause) {
                this.video.target.pause()
            }
            this.video.hasPause = true
        },
        handleMediaPre(obj)  {// 视频播放
            const ref = this.$refs[obj.videoRef];
            this.video.hasPause = !this.video.hasPause
            this.video.target = ref
            if(!this.video.hasPause) {
                ref && ref.play()
            } else {
                ref && ref.pause()
            }
        },
        // emit
        emitHandleAgree() {
            this.middleEmitChild.fn('getCommentsAjax')
            this.middleEmitChild.fn('getAllCommentAjax')
        }
    },
}
