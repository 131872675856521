
export default {
    name: 'ProductReviewTranslate',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        middleEmitChild: Object
    },
    data() {
        return {
            content: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        setContentData(obj) {
            this.content = obj
        },
        handleTranslate() {
            this.middleEmitChild.fn('handleTranslate', {info: this.info, ...this.content})
        }
    },
}
