import { render, staticRenderFns } from "./ProductResourceThree.vue?vue&type=template&id=7eb67ac0&scoped=true&"
import script from "./ProductResourceThree.vue?vue&type=script&lang=js&"
export * from "./ProductResourceThree.vue?vue&type=script&lang=js&"
import style0 from "./ProductResourceThree.vue?vue&type=style&index=0&id=7eb67ac0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eb67ac0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default})
