
import ProductSize from '../components/ProductSize'

export default {
    name: 'ProductSku',
    components: {
        ProductSize
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            rsInfo: {},
            skuCompo: {
                options: {
                    showOnlySku: true,
                    skuMId: ''
                }
            },
            skuSelect: {
                hasOnlySku: false
            },
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        },
        '$route.params.seoUrl': {
            handler() {
                this.setData()
            },
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            const query = this.$route.query
            const params = this.$route.params
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            // 初始化选择sku
            const skus = this.rsInfo.skus || []
            const match = skus.find(p => {
                let hasMatch = p.seoUrl == params.seoUrl
                if(query.sku) {
                    hasMatch = p.sellerSku == query.sku
                }
                return hasMatch
            }) || {}
            this.skuCompo.options.skuMId = match.id
        },
        changeSkuData(obj) {
            const sku = obj.sku || {};
            this.skuSelect.hasOnlySku = sku.hasOnlySku
            this.middleEmit.fn('changeSkuData', obj)
        },
        changeSkuSelect(obj) {
            const params = this.$route.params
            const query = this.$route.query
            this.skuSelect = {
                hasOnlySku: this.skuSelect.hasOnlySku,
                ...obj.select
            }
            const select = obj.select || {}
            const seoUrl = select.seoUrl
            if(select.hasAllSelect && select.hasClick) {
                // this.$router.replace({
                //     name: 'Product-Seo',
                //     params: {
                //         seoUrl
                //     },
                //     query: {
                //         ...query,
                //         sku: select.sellerSku || ''
                //     }
                // })
                // return
                window.history.replaceState({}, '', `${seoUrl}?sku=${select.sellerSku}`);
            }
            this.middleEmit.fn('changeSkuSelect', this.skuSelect)
        },
        // sku同步
        handleSku(obj) {
            const ref = this.$refs.ProductYfnSku;
            ref.handleSelect(obj.target)
        },
        // size
        handleSize() {
            this.$refs.ProductSize.showPopup()
        }
    },
}
