
export default {
    name: 'ProductRelatedCate',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            cateList: {
                visible: false,
                list: [],
                isMore:false,
                title:''
            },
            keywordList:(e)=>{
                return this.cateList.isMore ? e : e.slice(0,20)
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.cateList.list = this.info?.relatedCate?.cateDetails || []
            this.cateList.title = this.info?.relatedCate?.titleName || this.$translate('Related Categories')
            this.cateList.list.forEach(p => {
                p.length > 20 && (p = p.slice(0, 20))
            })
            this.cateList.visible = !!this.cateList.list.length
        },
        handleKeyword(val) {
            window.location.href = '/collection/'+val.seoUrl
        }
    },
}
