
export default {
    name: 'ProductMost',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            visible: false,
            rsInfo: {}, // 商品所有信息
            topRange: {}
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.rsInfo = JSON.parse(JSON.stringify(this.info))
                this.setData()
            },
            deep: true,
            immediate: true
        },
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            if(this.rsInfo.topRange) {
                this.visible = true
                this.topRange = this.rsInfo.topRange
            }
        },
        // 跳转畅销
        handleBest() {
            this.$router.push({
                name: 'Activity-BestSeller',
                query: {
                    chartsId: this.topRange.chartsId
                }
            })
        }
    }
}
