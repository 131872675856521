import { render, staticRenderFns } from "./ProductSize.vue?vue&type=template&id=a6c54038&scoped=true&"
import script from "./ProductSize.vue?vue&type=script&lang=js&"
export * from "./ProductSize.vue?vue&type=script&lang=js&"
import style0 from "./ProductSize.vue?vue&type=style&index=0&id=a6c54038&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6c54038",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
