
export default {
    name: 'ProductResourceCoupon',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            list: [],
            visible: false
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                if(n.productCode) {
                    this.getResourceAjax()
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        getResourceAjax() {
            this.$api.product.getResource({
                spuCode: this.info.productCode,
                showPosition: 2
            }).then(response => {
                const res = response.result || [];
                this.list = res
                this.visible = !!this.list.length
            })
        },
    }
}
