
export default {
    name: 'ProductReviewHelpful',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            help: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.help = this.info || {}
    },
    methods: {
        handleAgree() {
            if(!this.$login()) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            const likeStatus = !this.help.likeStatus;
            this.$api.review.setAgree({
                spuCommentId: this.help.id,
                status: likeStatus
            }).then(response => {
                const res = response?.result
                if(response.code == 'success') {
                    this.help.likeStatus = !this.help.likeStatus
                    if(this.help.likeStatus) {
                        this.help.helpFul += 1
                    } else {
                        this.help.helpFul -= 1
                    }
                }
                this.$emit('emit-handle-agree')
            })
        }
    },
}
