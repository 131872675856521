import { render, staticRenderFns } from "./ProductLike.vue?vue&type=template&id=e52d3044&scoped=true&"
import script from "./ProductLike.vue?vue&type=script&lang=js&"
export * from "./ProductLike.vue?vue&type=script&lang=js&"
import style0 from "./ProductLike.vue?vue&type=style&index=0&id=e52d3044&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e52d3044",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default})
