import { render, staticRenderFns } from "./ProductRelatedCate.vue?vue&type=template&id=41e3270d&scoped=true&"
import script from "./ProductRelatedCate.vue?vue&type=script&lang=js&"
export * from "./ProductRelatedCate.vue?vue&type=script&lang=js&"
import style0 from "./ProductRelatedCate.vue?vue&type=style&index=0&id=41e3270d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e3270d",
  null
  
)

export default component.exports